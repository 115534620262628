import React, { useState, useRef } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  CarouselFooter,
  CarouselImage,
  CarouselImageOverlay,
  CarouselImages,
  CarouselMain,
  CarouselSlide,
  CarouselSlider,
  CarouselWrapper,
} from './index.style'
import CarouselDots from '../CarouselDots'
import Grid from '../Grid'
import GridItem from '../GridItem'
import RichText from '../RichText'
import Spacer from '../Spacer'
import VimeoPlayer from '../VimeoPlayer'

const Carousel = ({ id, slides, endless }) => {
  const [previousIndex, setPreviousIndex] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const carouselImageRefs = useRef([])

  const sliderSettings = {
    infinite: endless ? true : false,
    beforeChange: (oldIndex, newIndex) => {
      setPreviousIndex(oldIndex)
      setActiveIndex(newIndex)

      gsap.set(carouselImageRefs.current[newIndex], {
        opacity: 0,
      })

      gsap.to(carouselImageRefs.current[newIndex], {
        opacity: 1,
        duration: 0.6,
        delay: 0,
        ease: 'power1.inOut',
      })
    },
  }

  return (
    <CarouselWrapper id={id}>
      <Grid gutters={false}>
        <GridItem tabletL={7} tabletLOrder={2} desk={8}>
          <CarouselImages>
            {React.Children.toArray(
              slides.map((slide, slideIndex) => (
                <CarouselImage
                  ref={ref => (carouselImageRefs.current[slideIndex] = ref)}
                  style={{
                    zIndex:
                      slideIndex === activeIndex
                        ? 2
                        : slideIndex === previousIndex
                        ? 1
                        : undefined,
                  }}
                >
                  {slide.vimeoId ? (
                    <>
                      <VimeoPlayer
                        id={slide.vimeoId}
                        autoplay
                        muted
                        loop
                        autopause={false}
                        controls={false}
                        playState={slideIndex === activeIndex}
                        rounded={false}
                      />
                      <CarouselImageOverlay />
                    </>
                  ) : (
                    <GatsbyImage
                      image={slide.image.gatsbyImageData}
                      alt={slide.image.description}
                      loading="eager"
                    />
                  )}
                </CarouselImage>
              ))
            )}
          </CarouselImages>
        </GridItem>

        <GridItem tabletL={5} tabletLOrder={1} desk={4}>
          <CarouselMain>
            <CarouselSlider>
              <Slider {...sliderSettings}>
                {React.Children.toArray(
                  slides.map(slide => (
                    <CarouselSlide>
                      <RichText content={slide.carouselTitle} heading2 />

                      <Spacer size={18} />

                      <RichText content={slide.text} paragraphSize="regular" />
                    </CarouselSlide>
                  ))
                )}
              </Slider>

              <CarouselFooter>
                {slides.length > 1 && (
                  <CarouselDots slides={slides} activeIndex={activeIndex} />
                )}
              </CarouselFooter>
            </CarouselSlider>
          </CarouselMain>
        </GridItem>
      </Grid>
    </CarouselWrapper>
  )
}

Carousel.propTypes = {
  id: PropTypes.string,
  slides: PropTypes.array,
}

export default Carousel
