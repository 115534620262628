import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const HowHeroMain = styled.div``

export const HowHeroImage = styled.div`
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6.4rem;

  ${mq.tabletL} {
    max-width: none;
    margin-top: 0;
  }
`
