import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import {
  HowResultsMain,
  HowResultsSlider,
  HowResultsSliderFooter,
} from './index.style'
import Spacer from '../../Spacer'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { Heading2 } from '../../TextStyles'
import RichText from '../../RichText'
import ScrollSection from '../../ScrollSection'
import { colors } from '../../../styles/vars/colors.style'
import ImageCompare from '../../ImageCompare'
import CarouselDots from '../../CarouselDots'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const HowResults = ({ title, text, sliders }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const sliderSettings = {
    className: 'slick-slider--dark',
    draggable: false,
    swipe: false,
    infinite: false,
    beforeChange: (oldIndex, newIndex) => {
      setActiveIndex(newIndex)
    },
  }

  return (
    <ScrollSection>
      <HowResultsMain>
        <Spacer size={[84, 240]} />

        <Container>
          <Grid>
            <GridItem
              tabletP={5}
              tabletPAlign="center"
              tabletL={3}
              tabletLStart={2}
            >
              <Heading2 color={colors.blue}>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading2>

              <Spacer size={[18, 27]} />

              <RichText content={text} />
            </GridItem>

            <GridItem tabletP={7} tabletL={7} tabletLStart={6}>
              <AnimateSlideIn>
                <HowResultsSlider
                  leftText={sliders[0].leftText}
                  rightText={sliders[0].rightText}
                >
                  <Slider {...sliderSettings}>
                    {React.Children.toArray(
                      sliders.map(slider => (
                        <ImageCompare
                          leftText={slider.leftText}
                          leftImage={slider.leftImage}
                          rightText={slider.rightText}
                          rightImage={slider.rightImage}
                        />
                      ))
                    )}
                  </Slider>

                  <HowResultsSliderFooter>
                    <CarouselDots
                      slides={sliders}
                      activeIndex={activeIndex}
                      theme="dark"
                    />
                  </HowResultsSliderFooter>
                </HowResultsSlider>
              </AnimateSlideIn>
            </GridItem>
          </Grid>
        </Container>

        <Spacer size={[110, 240]} />
      </HowResultsMain>
    </ScrollSection>
  )
}

HowResults.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  sliders: PropTypes.array,
}

export default HowResults
