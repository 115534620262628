import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import HowHero from '../components/HowItWorks/HowHero'
import HowAccordion from '../components/HowItWorks/HowAccordion'
import HowCarousels from '../components/HowItWorks/HowCarousels'
import HowResults from '../components/HowItWorks/HowResults'
import PageNav from '../components/PageNav'

const HowItWorksPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    heroTitle,
    heroText: { heroText },
    heroImage,
    designTitle,
    preparationTitle,
    cementationTitle,
    designSummary,
    designSummaryImage,
    preparationSummary,
    preparationSummaryImage,
    cementationSummary,
    cementationSummaryImage,
    designSlides,
    preparationSlides,
    cementationSlides,
    resultsTitle,
    resultsText,
    resultsCompareSliders,
    pageNavigation,
  } = data.contentfulHowItWorks

  const howAccordionData = [
    {
      title: designTitle,
      content: designSummary,
      image: designSummaryImage,
    },
    {
      title: preparationTitle,
      content: preparationSummary,
      image: preparationSummaryImage,
    },
    {
      title: cementationTitle,
      content: cementationSummary,
      image: cementationSummaryImage,
    },
  ]

  const howCarouselsData = [
    {
      title: designTitle,
      slides: designSlides,
    },
    {
      title: preparationTitle,
      slides: preparationSlides,
    },
    {
      title: cementationTitle,
      slides: cementationSlides,
    },
  ]

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.howItWorks}
      />

      <HowHero title={heroTitle} text={heroText} image={heroImage} />

      <HowAccordion data={howAccordionData} />

      <HowCarousels data={howCarouselsData} />

      <HowResults
        title={resultsTitle}
        text={resultsText}
        sliders={resultsCompareSliders}
      />

      <PageNav
        title={pageNavigation.title}
        linkSlug={pageNavigation.pageLink.slug}
        linkText={pageNavigation.linkText}
        image={pageNavigation.image}
      />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          howItWorks
        }
      }
    }
    contentfulHowItWorks {
      seoTitle
      seoDescription {
        seoDescription
      }
      heroTitle
      heroText {
        heroText
      }
      heroImage {
        gatsbyImageData
        description
      }
      designTitle
      preparationTitle
      cementationTitle
      designSummary {
        raw
      }
      designSummaryImage {
        gatsbyImageData
        description
      }
      preparationSummary {
        raw
      }
      preparationSummaryImage {
        gatsbyImageData
        description
      }
      cementationSummary {
        raw
      }
      cementationSummaryImage {
        gatsbyImageData
        description
      }
      designSlides {
        carouselTitle {
          raw
        }
        text {
          raw
        }
        image {
          gatsbyImageData
          description
        }
        vimeoId
      }
      preparationSlides {
        carouselTitle {
          raw
        }
        text {
          raw
        }
        image {
          gatsbyImageData
          description
        }
        vimeoId
      }
      cementationSlides {
        carouselTitle {
          raw
        }
        text {
          raw
        }
        image {
          gatsbyImageData
          description
        }
        vimeoId
      }
      resultsTitle
      resultsText {
        raw
      }
      resultsCompareSliders {
        leftText
        leftImage {
          gatsbyImageData
          description
        }
        rightText
        rightImage {
          gatsbyImageData
          description
        }
      }
      pageNavigation {
        ...PageLink
      }
    }
  }
`

export default HowItWorksPage
