import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'
import { SpacerMain } from '../../Spacer/index.style'

export const HowResultsMain = styled.div`
  *:not(${SpacerMain}) {
    min-height: 0;
    min-width: 0;
  }
`

export const HowResultsSlider = styled.div`
  position: relative;

  .slick-list {
    position: relative;

    :before,
    :after {
      position: absolute;
      ${clamp('bottom', 20, 48)}
      z-index: 1;
      color: ${colors.lightGrey};
    }

    :before {
      content: ${props => `'${props.leftText}'`};
      ${clamp('left', 32, 66)}
      transform-origin: 0% 100%;
      transform: rotate(-90deg);
    }

    :after {
      content: ${props => `'${props.rightText}'`};
      ${clamp('right', 32, 66)}
      transform-origin: 100% 100%;
      transform: rotate(-90deg) translate(100%, 100%);
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 0.1rem;
    border-color: ${transparentize(0.5, colors.midGrey)};
  }

  .slick-prev {
  }

  .slick-next {
    transform: translateX(calc(100% + 1.5rem));
  }

  .slick-list {
    ${clamp('border-radius', 16, 24)}
    background-color: ${colors.dark};
    overflow: hidden;
  }
`

export const HowResultsSliderFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  ${clamp('padding-top', 18, 28)}
  ${clamp('padding-bottom', 8, 12)}
`
