import React from 'react'
import PropTypes from 'prop-types'
import ScrollSection from '../../ScrollSection'
import Spacer from '../../Spacer'
import Container from '../../Container'
import Carousel from '../../Carousel'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import ScrollToNav from '../../ScrollToNav'
import { stringToId } from '../../../utils/utils'

const HowCarousels = ({ data }) => (
  <>
    <ScrollToNav items={data} offset={-180} />

    <Spacer size={[32, 160]} />

    <ScrollSection>
      <Container allowOverflow>
        <AnimateSlideIn>
          <Carousel
            id={data[0].title.toLowerCase()}
            slides={data[0].slides}
            endless={false}
          />
        </AnimateSlideIn>

        <Spacer size={[50, 240]} />
      </Container>
    </ScrollSection>

    <ScrollSection>
      <Container allowOverflow>
        <AnimateSlideIn>
          <Carousel
            id={stringToId(data[1].title.toLowerCase())}
            slides={data[1].slides}
            endless={false}
          />
        </AnimateSlideIn>

        <Spacer size={[50, 240]} />
      </Container>
    </ScrollSection>

    <ScrollSection>
      <Container allowOverflow>
        <AnimateSlideIn>
          <Carousel
            id={data[2].title.toLowerCase()}
            slides={data[2].slides}
            endless={false}
          />
        </AnimateSlideIn>
      </Container>
    </ScrollSection>
  </>
)

HowCarousels.propTypes = {
  data: PropTypes.array,
}

export default HowCarousels
