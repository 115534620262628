import React from 'react'
import PropTypes from 'prop-types'
import { HowHeroImage, HowHeroMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { Heading1, TextBody } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const HowHero = ({ title, text, image }) => (
  <ScrollSection>
    <HowHeroMain>
      <Container>
        <Spacer size={[116, 366]} />

        <Grid>
          <GridItem tabletL={6} tabletLStart={2}>
            <Heading1 as="h2">
              <AnimateSplitText heading>{title}</AnimateSplitText>
            </Heading1>

            <Spacer size={32} />

            <TextBody maxWidth={30}>
              <AnimateSplitText delay={0.3}>{text}</AnimateSplitText>
            </TextBody>
          </GridItem>

          <GridItem tabletL={5} desk={4} deskL={3} deskLStart={9}>
            <HowHeroImage>
              <AnimateSlideIn delay={0.1}>
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.description}
                  objectFit="contain"
                />
              </AnimateSlideIn>
            </HowHeroImage>
          </GridItem>
        </Grid>

        <Spacer size={[74, 225]} />
      </Container>
    </HowHeroMain>
  </ScrollSection>
)

HowHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
}

export default HowHero
