import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { SpacerMain } from '../Spacer/index.style'

export const CarouselWrapper = styled.div`
  overflow: hidden;
  background-color: ${colors.blue};
  ${clamp('border-radius', 16, 24)}
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  *:not(${SpacerMain}) {
    min-height: 0;
    min-width: 0;
  }
`

export const CarouselImages = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.darkGrey};

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${(234 / 327) * 100}%;
  }
`

export const CarouselImage = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.darkGrey};
`

export const CarouselImageOverlay = styled.div`
  position: absolute;
  inset: 0;
`

export const CarouselMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.lightGrey};
`

export const CarouselSlider = styled.div`
  position: relative;
  margin-top: auto;

  .slick-track {
    align-items: flex-end;
  }

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    bottom: 3.85rem;
    transform: translate(0, 50%);

    ${mq.tabletL} {
      transform: translateY(calc(50% - 0.7rem));
      ${clamp('bottom', 38, 78, breakpoints.tabletL, breakpoints.contained)}
    }
  }

  .slick-prev {
    left: 2.7rem;

    ${mq.tabletL} {
      ${clamp('left', 27, 64, breakpoints.tabletL, breakpoints.contained)}
    }
  }

  .slick-next {
    right: 2.7rem;

    ${mq.tabletL} {
      transform: translate(calc(100% + 1.5rem), calc(50% - 0.7rem));
      ${clamp('left', 27, 64, breakpoints.tabletL, breakpoints.contained)};
    }
  }
`

export const CarouselSlide = styled.div`
  padding: 2.7rem 2.7rem 0;

  ${mq.tabletL} {
    padding-top: 0;
    ${clamp('padding-left', 27, 64, breakpoints.tabletL, breakpoints.contained)}
    ${clamp(
      'padding-right',
      27,
      64,
      breakpoints.tabletL,
      breakpoints.contained
    )}
  }
`

export const CarouselFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.7rem;
  padding-left: 2.7rem;
  padding-right: 2.7rem;

  ${mq.tabletL} {
    justify-content: flex-end;
    ${clamp('height', 76, 156, breakpoints.tabletL, breakpoints.contained)}
    ${clamp('padding-left', 27, 64, breakpoints.tabletL, breakpoints.contained)}
    ${clamp(
      'padding-right',
      27,
      64,
      breakpoints.tabletL,
      breakpoints.contained
    )}
  }
`
